import * as React from 'react';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Paper,
} from '@mui/material';
import { useFormik, FormikTouched } from 'formik';
import Success from '@/fleet/pages/public/Card/components/Success';
import * as step0 from './step0';
import * as step1 from './step1';
import * as step2 from './step2';
import { Confirmation } from './step3/Confirmation';
import { FormFooter } from './components/FormFooter';
import { stepStyles } from './styles';
import * as mutations from './mutations';

enum MapperStep {
  Step0 = 0,
  Step1 = 1,
  Step2 = 2,
  Step3 = 3,
}

type InitialValuesType =
  | step0.FormValuesType
  | step1.FormValuesType
  | step2.FormValuesType;

export type CombinedFormValuesType = step0.FormValuesType &
  step1.FormValuesType &
  step2.FormValuesType;

export function Credit() {
  const [activeStep, setActiveStep] = React.useState(MapperStep.Step0);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const steps = [
    'Business Contact Information',
    'Business Details',
    'Owner Information',
    'Confirmation',
  ];

  const isLastStep = activeStep === steps.length - 1;

  const getFormik = <T extends InitialValuesType>(
    initialValues: T,
    validationSchema: any,
  ) =>
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: () => {
        handleNext();
      },
      validateOnChange: false,
      validateOnBlur: false,
    });

  const formikStep0 = getFormik(step0.initialValues, step0.validationSchema);
  const formikStep1 = getFormik(step1.initialValues, step1.validationSchema);
  const formikStep2 = getFormik(step2.initialValues, step2.validationSchema);

  const handleSubmit = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    const allValues: CombinedFormValuesType = {
      ...formikStep0.values,
      ...formikStep1.values,
      ...formikStep2.values,
    };

    try {
      await mutations.createCreditApplicationService(allValues);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert(
        `There was an error submitting the form. Please try again. Error: ${error}`,
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const mapperStepSubmit = {
    [MapperStep.Step0]: formikStep0.handleSubmit,
    [MapperStep.Step1]: formikStep1.handleSubmit,
    [MapperStep.Step2]: formikStep2.handleSubmit,
    [MapperStep.Step3]: handleSubmit,
  };

  const mapperStepFormik = {
    [MapperStep.Step0]: formikStep0,
    [MapperStep.Step1]: formikStep1,
    [MapperStep.Step2]: formikStep2,
    [MapperStep.Step3]: null,
  };

  const allValues = {
    ...formikStep0.values,
    ...formikStep1.values,
    ...formikStep2.values,
    numberOfTrucks: formikStep1.values.numberOfTrucks.toString(),
  };

  const mapperStepForm = {
    [MapperStep.Step0]: <step0.Form formik={formikStep0} />,
    [MapperStep.Step1]: <step1.Form formik={formikStep1} />,
    [MapperStep.Step2]: <step2.Form formik={formikStep2} />,
    [MapperStep.Step3]: <Confirmation values={allValues} />,
  };

  const handleNext = async () => {
    let isValid = false;
    const currentFormik = mapperStepFormik[activeStep as MapperStep];

    if (currentFormik) {
      isValid = await currentFormik
        .validateForm()
        .then(() => currentFormik.isValid);
    }

    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      if (currentFormik) {
        currentFormik.setTouched(
          Object.keys(currentFormik.values).reduce(
            (acc, key) => {
              return { ...acc, [key]: true };
            },
            {} as FormikTouched<typeof currentFormik.values>,
          ),
          true,
        );
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        minWidth: '55%',
        maxWidth: '900px',
        my: { xs: 3, md: 6 },
        p: { xs: 2, md: 3 },
      }}
    >
      {isSubmitted ? (
        <Success />
      ) : (
        <>
          <Box sx={{ my: 5 }}>
            <Typography component="h1" variant="h4" align="center">
              Fuel Credit Application
            </Typography>
          </Box>
          <Stepper activeStep={activeStep} sx={{ py: 1 }} alternativeLabel>
            {steps.map((label) => (
              <Step key={label} sx={stepStyles}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <form onSubmit={mapperStepSubmit[activeStep]}>
            {mapperStepForm[activeStep]}
            <FormFooter
              handleBack={handleBack}
              handleSubmit={mapperStepSubmit[activeStep]}
              activeStep={activeStep}
              isLastStep={isLastStep}
              isSubmitting={isSubmitting}
            />
          </form>
        </>
      )}
    </Paper>
  );
}
