import React from 'react';
import { Box, Typography, Paper, Container } from '@mui/material';
import logo from '../../logo.png';
import { Config } from '../../config/env';

const SafariWarning: React.FC = () => {
  // Check if we're in a test/staging environment or local development
  const isTestOrLocalEnvironment = () => {
    const authUrl = Config.AUTH_URL || '';
    return authUrl.includes('test') || authUrl.includes('localhost');
  };

  const handleContinueWithSafari = () => {
    // Store a flag in localStorage to indicate the user has chosen to continue with Safari
    localStorage.setItem('continueWithSafari', 'true');
    // Reload the page to continue with authentication
    window.location.reload();
  };
  return (
    <Container maxWidth="md" sx={{ mt: 8 }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: '1px solid lightGray',
          borderRadius: 2,
        }}
      >
        <Box
          component="img"
          sx={{
            height: 63,
            width: 63,
            marginBottom: 5,
          }}
          alt="Onramp logo"
          src={logo}
        />
        <Typography variant="h5" component="h1" gutterBottom>
          Browser Not Supported
        </Typography>

        <Box sx={{ my: 2 }}>
          <Typography variant="body1" paragraph align="center">
            Safari is currently not supported by ONRAMP, please download our
            preferred browser Google Chrome
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 4,
              my: 3,
              flexWrap: 'wrap',
            }}
          >
            <BrowserOption
              name="Chrome"
              action={() =>
                window.open('https://www.google.com/chrome/', '_blank')
              }
              actionText="Download"
            />
            {isTestOrLocalEnvironment() && (
              <BrowserOption
                name="Continue with Safari"
                action={handleContinueWithSafari}
                actionText="Proceed"
              />
            )}
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

interface BrowserOptionProps {
  name: string;
  action: () => void;
  actionText: string;
}

const BrowserOption: React.FC<BrowserOptionProps> = ({
  name,
  action,
  actionText,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: 2,
      border: '1px solid #e0e0e0',
      borderRadius: 1,
      width: '120px',
      '&:hover': {
        backgroundColor: '#f5f5f5',
        cursor: 'pointer',
      },
    }}
    onClick={action}
  >
    <Typography variant="h6" component="div" textAlign="center">
      {name}
    </Typography>
    <Typography variant="body2" color="text.secondary">
      {actionText}
    </Typography>
  </Box>
);

export default SafariWarning;
