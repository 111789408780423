import Keycloak from 'keycloak-js';

import { Config } from '@/fleet/config/env';

const keycloakClient = new Keycloak({
  url: `${Config.AUTH_URL}/auth`,
  realm: 'app',
  clientId: 'web',
});

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void) => {
  keycloakClient
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      pkceMethod: 'S256',
      checkLoginIframe: false,
    })
    .then((authenticated: any) => {
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = keycloakClient.login;

const doLogout = keycloakClient.logout;

const getToken = () => keycloakClient.token;

const isLoggedIn = () => !!keycloakClient.token;

const getCarrierUuid = () => {
  return keycloakClient.tokenParsed?.carrier_uuid;
};

const getUserEmail = () => {
  return keycloakClient.tokenParsed?.email;
};

const getUserSid = () => {
  return keycloakClient.tokenParsed?.sid;
};

const updateToken = (
  successCallback:
    | ((value: boolean) => boolean | PromiseLike<boolean>)
    | null
    | undefined,
) => keycloakClient.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => keycloakClient.tokenParsed?.preferred_username;

const getEmail = () => keycloakClient.tokenParsed?.email;

const getFirstName = () => {
  return keycloakClient.tokenParsed?.given_name;
};

const getInitial = () => {
  return keycloakClient.tokenParsed?.given_name.charAt(0);
};

const isStaffUser = () => {
  return (
    keycloakClient.hasRealmRole('staff') &&
    getEmail() &&
    getEmail().includes('@onrampcard.com')
  );
};

const hasRole = (roles: any[]) =>
  roles.some((role: string) => keycloakClient.hasRealmRole(role));

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getFirstName,
  hasRole,
  getCarrierUuid,
  getInitial,
  isStaffUser,
  getUserEmail,
  getUserSid,
};

export default AuthService;
